import React, { useRef, useState } from "react";
import { BsFillPinMapFill } from 'react-icons/bs';
import { AiOutlinePhone, AiOutlineMail } from 'react-icons/ai';
import Fade from 'react-reveal/Fade';
import './ContactForm.css';
import emailjs from 'emailjs-com';

const ContactForm = () => {
    const form = useRef();
    const [buttonText, setButtonText] = useState('Submit');
    const [statusMessage, setStatusMessage] = useState('');

    const sendEmail = (e) => {
        e.preventDefault();
        setButtonText('Sending...');

        emailjs.sendForm('service_bdfl0ie', 'template_0v7bwce', form.current, 'CLcHWAKSemVMd1_sU')
            .then((result) => {
                console.log(result.text);
                setButtonText('Message Sent');
                setStatusMessage('Your message was successfully sent. We will get back to you shortly.');
            }, (error) => {
                console.log(error.text);
                setButtonText('Submit');
                setStatusMessage('Oops! Something went wrong. Please try again.');
            });

        e.target.reset();
    };

    return (
        <section className="contact-container">
            <Fade left>
                <div className="contact-cards-container">
                    <div className="get_form_inner2">
                        <h2>CALL US</h2>
                        <p><AiOutlinePhone /><a href="tel:3238018511"> +1 (323) 452-6659</a></p>
                    </div>
                    <div className="get_form_inner2">
                        <h2>EMAIL</h2>
                        <p><AiOutlineMail /> info@reddingrehab.com</p>
                    </div>
                    <div className="get_form_inner2">
                        <h2>LOCATION</h2>
                        <p><BsFillPinMapFill /> 3580 Santa Rosa Way, Redding, CA 96003</p>
                    </div>
                </div>
            </Fade>

            <div className="get_form_inner">
                <div className="get_form_inner_text">
                    <h3>Get In Touch</h3>
                    <p><i>We'll keep your contact information strictly confidential.</i></p>
                </div>
                <br />
                <form ref={form} onSubmit={sendEmail}>
                    <div className="inputs">
                        <input 
                            type="text" 
                            name="name" 
                            placeholder="Your Name" 
                            aria-label="Your Name" 
                            pattern="[A-Za-z\s]+" 
                            title="Name should only contain letters." 
                            required 
                        /> <br />
                        <input 
                            type="email" 
                            name="email" 
                            placeholder="Your Email" 
                            aria-label="Your Email" 
                            required 
                        /> <br />
                        <input 
                            type="tel" 
                            name="phone" 
                            placeholder="Your Phone" 
                            aria-label="Your Phone" 
                            pattern="^\d{10}$" 
                            title="Phone number should be 10 digits." 
                            required 
                        /> <br />
                        <input 
                            type="text" 
                            name="subject" 
                            placeholder="Subject" 
                            aria-label="Subject" 
                            required 
                        /> <br />
                    </div>
                    <div>
                        <textarea 
                            name="message" 
                            placeholder="How can we help?" 
                            cols="50" 
                            rows="8" 
                            aria-label="Message" 
                            required
                        ></textarea>
                        <input type="submit" value={buttonText} />
                    </div>
                </form>
                {statusMessage && <p className="status-message">{statusMessage}</p>}
            </div>
        </section>
    );
}

export default ContactForm;
